/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

 (function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $('#nav-trigger').click(function(){
          $('#nav').toggleClass('js-show');
          $(this).toggleClass('js-closed');
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        $('input[type=text]').focus(function() {
          $('label[for=' + this.id + ']').addClass('js-active');
        }).blur(function() {
        $("label").removeClass("js-show");
        });
      }
    },
    // Home page
    'page_template_page_home': {
      init: function() {
        // JavaScript to be fired on the home page template
        $('#slider').slick({
          dots: true,
          speed: 500,
          adaptiveHeight: true,
          autoplay: true,
          autoplaySpeed: 5000,
          arrows: false,

          prevArrow: '<button type="button" class="slick-prev"><svg class="svg--arrow" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"viewBox="0 0 161.495 444.5" xml:space="preserve"><path id="Shape" fill-rule="evenodd" clip-rule="evenodd" d="M161.495,444.5L45.612,222.258L161.495,0h-45.604 L0,222.258L115.892,444.5H161.495z"/></svg></button>',
          nextArrow: '<button type="button" class="slick-next"><svg class="svg--arrow svg--arrow--right" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"viewBox="0 0 161.495 444.5" xml:space="preserve"><path id="Shape" fill-rule="evenodd" clip-rule="evenodd" d="M161.495,444.5L45.612,222.258L161.495,0h-45.604 L0,222.258L115.892,444.5H161.495z"/></svg></button>'
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page template, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  function mobileSbar(){
    var e=$(".site-sidebar .gform_widget .gform_wrapper");
    if($(window).width()<900) {
      $(e).hide();
    } else {
      $(e).show();
    }
    $(".qt_btn").click(function(){
      jQuery(e).toggle();
    if($(this).text() == 'Request a Quote') {
      $(this).text('Hide Form');
     } else if($(this).text() == 'Hide Form') {
      $(this).text('Request a Quote');
     }
     });
  }

    mobileSbar();

  function mobSideNav(){
    var e=$("#side_nav");
    if($(window).width()<900) {
      $(e).hide();
    } else {
      $(e).show();
    }
    $("#side-trigger").click(function(){
      $(e).toggle();
     if($(this).text() == 'View Services') {
      $(this).text('Hide Menu');
     } else if($(this).text() == 'Hide Menu') {
      $(this).text('View Services');
     }
    });
  }
  mobSideNav();
function crnTri() {
  var e = $(".site-sidebar .gform_widget")
  var homeFrm = $('.gform_confirmation_wrapper');

  if(homeFrm.is(':visible')) {
    $('.frm_tri, .btm_tri, .qt_btn').hide();

  }
}
crnTri();
  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
